import ClientList from "../ClientList/ClientList";

// import "./ClientsSection.css";

const ClientsSection = () => {
  return (
    <div className="">
      <p className="text-center text-black text-3xl sm:text-5xl font-black my-10 px-8 sm:px-0">
        Unsere <span className="text-rose-600">Zufriedene Kunden</span>
      </p>
      <ClientList direction="left" />
      <ClientList direction="right" />
    </div>
  );
};

export default ClientsSection;
