import React from "react"
import OnlineCourses from "../allcourses/OnlineCourses"
import "../allcourses/courses.css"
const HAbout = () => {
  return (
    <>
      <section className='homeAbout'>
        <OnlineCourses />
      </section>
    </>
  )
}

export default HAbout
