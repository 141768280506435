import Marquee from "react-fast-marquee";

import ClientCard from "../ClientCard/ClientCard";

const ClientList = (props) => {
  return (
    <div className="py-5 ">
      <Marquee
        pauseOnHover
        className="  w-full "
        speed={30}
        direction={props.direction}
      >
        <ClientCard
          client="Andre Bott"
          // status="Leitender Krankenpfleger"
          review="Absolut empfehlenswerter Pflegedienst.
    Die Mitarbeiter sind kompetent und kümmern sich sehr gut um das Wohlergehen. Flexible Zeitenänderungen und Absprachen laufen hervorragend.
    Der Gesundheitszustand oder Auffälligkeiten werden direkt mit den Angehörigen besprochen."
        />

        <ClientCard
          client="Stefan Klein"
          // status="Medizinische Leiterin"
          review="Sehr kompetentes und freundliches Team.
    Sehr gute Beratung und eine sehr gute professionelle Pflege. Man fühlt sich sehr gut aufgehoben.
    Werde diesen Pflegedienst auf jeden Fall weiter empfehlen.
    Vielen Dank an das tolle Team."
        />

        <ClientCard
          client="Karlheinz M."
          // status="Klinikmanager"
          review="Sehr engagierte Mitarbeiter und immer höflich. Die Betreuung hier ist sehr gut, wenn es darum geht, sich um pflegebedürftige Menschen mit Herz und Leidenschaft zu kümmern. Es könnte nicht besser sein - ich kann es nur empfehlen."
        />

        <ClientCard
          client="mone b."
          // status="Chefarzt"
          review="Ich kann den Pflegedienst sehr empfehlen. Meine Familie wird durch diese Unterstützung sehr geholfen. Die PDL Frau S. bemüht sich immer, im Interesse des Patienten Lösungen zu finden.
    Herzlichen Glückwunsch an ALLE!!!"
        />

        <ClientCard
          client="Harald Bernhart"
          // status="Praxismanager"
          review="Sehr zuverlässiger Pflegedienst, äußerst freundliches und kompetentes Personal.
    Ich kann es nur empfehlen."
        />

        <ClientCard
          client="Ahson Chaudhry"
          // status="Leiterin für Gesundheitsbetrieb"
          review="Unabhängig vom Service, der makellos ist, sind die Menschen auch einfach erstklassig. Besonders in der Branche wünschen sich die Menschen mehr Pflegedienste wie diesen, bei denen es nicht nur um das Geld geht."
        />

        <ClientCard
          client="Khi Ch"
          // status="Krankenhauskoordinator"
          review="Vielen Dank an das Team. Hier liegt der Fokus immer noch auf dem Kunden und sie werden ordnungsgemäß betreut. Das Personal ist immer hilfsbereit und pünktlich.
    Rundum sehr zu empfehlen."
        />

        <ClientCard
          client="Serhat Y"
          // status="Medizinische Praxismanagerin"
          review="Kompetente und menschliche Pflege ist das, was Sie bei diesem Pflegedienst finden werden! Wir stehen persönlich zur Verfügung, um Ratschläge und Unterstützung für Pflegebedürftige und Angehörige zu leisten."
        />

        <ClientCard
          client="Der Hulk"
          // status="Pflegedirektor"
          review="Sehr hilfreich, konnte alle meine Fragen beantworten. Vielen Dank für die tolle Unterstützung!"
        />

        <ClientCard
          client="King Chief"
          // status="Klinikadministratorin"
          review="Ein sehr freundlicher und kompetenter Pflegedienst. Ich kann es nur empfehlen!"
        />

        <ClientCard
          client="Hilal G"
          // status="Gesundheitsaufsichtsperson"
          review="Sehr freundliches und kompetentes Team, man fühlt sich willkommen und gut aufgehoben 👏🏼👍🏻 ..."
        />

        {/* <ClientCard
          client="Michelle Rodriguez"
          status="Managerin des medizinischen Büros"
          review="Pflegedienst Maliks Verständnis für die Nuancen der Gesundheitsversorgung ist lobenswert. Ihre KI-gesteuerte Terminplanung integriert sich nahtlos in unsere Abläufe und ermöglicht es uns, optimale Patientenversorgung zu bieten. Herausragende Arbeit!"
        />
        <ClientCard
          client="Jason Lee"
          status="Gesundheitskoordinator"
          review="Die Kompetenz von Pflegedienst Malik in Gesundheitslogistik ist herausragend. Ihre KI-gesteuerte Terminplanung vereinfacht Aufgaben und ermöglicht es uns, hochwertige Pflege zu liefern. Sehr zu empfehlen!"
        /> */}
      </Marquee>
    </div>
  );
};

export default ClientList;
